<template>
    <section class="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800" id="certificado-digital">
        <div class="container relative">
            <div class="grid grid-cols-1 pb-6 text-center">
                <h3 class="font-semibold text-2xl leading-normal mb-4">Faça seu Certificado Digital</h3>

                <p class="text-slate-400 max-w-xl mx-auto">Aproveite agora para emitir seu certificado digital 100% online, em menos de 15 minutos!</p>
            </div><!--end grid-->

            <div class="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 mt-6">
                <div v-for="item in servicesData" :key="item.id" class="group rounded-md shadow dark:shadow-gray-700 relative bg-white dark:bg-slate-900 p-6 overflow-hidden h-fit">
                    <div class="flex items-center justify-center size-14 -rotate-45 bg-gradient-to-r from-transparent to-teal-500/10 text-teal-500 text-center rounded-full group-hover:bg-teal-500/10 duration-500">
                        <i :data-feather="item.icon" class="size-6 rotate-45"></i>
                    </div>

                    <div class="content mt-6 relative z-1">
                        <a href="" class="title text-lg font-semibold hover:text-teal-500">{{item.title}}</a>
                        <p class="text-slate-400 mt-3">{{item.desc}}</p>

                        <div class="mt-6">
                            <a :href="item.uri" class="text-teal-500">Mais... <i class="mdi mdi-chevron-right align-middle"></i></a>
                        </div>
                    </div>

                    <div class="absolute bottom-0 -end-16">
                        <i :data-feather="item.icon" class="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500"></i>
                    </div>
                </div>
            </div><!--end grid-->
        </div><!--end container-->
    </section>
</template>

<script>
 import feather from 'feather-icons'
    export default {
        data(){
            return{
                servicesData :[
                    {
                        id:1,
                        icon:'briefcase',
                        title:'e-CNPJ A1',
                        desc:'Garanta a autenticidade da sua empresa com o e-CNPJ A1! Segurança e praticidade para acessar serviços digitais. Adquira já o seu!',
                        uri: 'https://seucertificadodigital.com/loja/checkout?p=u68-sid-solucoes&item=eyJyYW5kIjoiMTczMzU4OTA0OTE1MTgiLCJpZCI6MX0='
                    },
                    {
                        id:2,
                        icon:'award',
                        title:'e-CPF A1',
                        desc:'Precisa assinar documentos digitais com confiança? O e-CPF A1 é a solução ideal. Compre agora e digitalize suas transações!',
                        uri: 'https://seucertificadodigital.com/loja/checkout?p=u68-sid-solucoes&item=eyJyYW5kIjoiMTczMzU4OTA0OTIzMDkiLCJpZCI6Mn0='
                    },
                    {
                        id:3,
                        icon:'user-check',
                        title:'e-CPF A3 Advogados',
                        desc:'Advogado, garanta já o seu primeiro certificado digital! Garanta seu e-CPF e assine com segurança e credibilidade.',
                        uri: '#contact'
                    },
                    {
                        id:4,
                        icon:'users',
                        title:'e-CPF A3 Produtor Rural',
                        desc:'Mais segurança e validade para suas assinaturas digitais! Obtenha seu e-CPF A3 e tenha tranquilidade em todos os processos.',
                        uri: '#contact'
                    },
                ]
            }
        },

        mounted() {
        feather.replace();
        }
    
    }
</script>