<template>
    <section class="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800" id="planos">
            <div class="container relative">
                <div class="grid grid-cols-1 pb-6 text-center">
                    <h3 class="font-semibold text-2xl leading-normal mb-4">Planos</h3>
                    <p class="text-slate-400 max-w-xl mx-auto">Escolha o pacote ideal para o seu negócio, do simples ao arrojado. Os planos da SiD Soluções tem um objetivo preciso e ambicioso: facilitar o trabalho dos nossos clientes.</p>
                </div><!--end grid-->

                <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    <div class="group p-6 relative overflow-hidden shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 rounded-md h-fit">
                        <h6 class="font-semibold mb-5 text-xl">EasyRestaurante</h6>

                        <div class="flex mb-5">
                            <span class="text-lg font-medium">R$</span>
                            <span class="price text-5xl h6 font-semibold mb-0">29,90</span>
                            <span class="text-lg font-medium self-end mb-1">/mês</span>
                        </div>

                        <ul class="list-none text-slate-400">
                            <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>1 usuário</span></li>
                            <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Aumente suas vendas com um nosso sistema integrado ao seu WhatsApp.</span></li>
                        </ul>
                        <a target="_blank" href="https://www.easyrestaurant.app.br/site/" class="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500/5 hover:bg-teal-500 text-teal-500 hover:text-white w-full mt-5">10 dias grátis</a>
                    </div><!--end content-->

                    <div class="group p-6 relative overflow-hidden shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 rounded-md h-fit">
                        <h6 class="font-semibold mb-5 text-xl">EasyGestor Fit</h6>

                        <div class="flex mb-5">
                            <span class="text-lg font-medium">R$</span>
                            <span class="price text-5xl h6 font-semibold mb-0">48,87</span>
                            <span class="text-lg font-medium self-end mb-1">/mês</span>
                        </div>

                        <ul class="list-none text-slate-400">
                            <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>1 usuário</span></li>
                            <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Emissoes de NF-e.</span></li>
                            <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Controle de Estoque e Financeiro</span></li>
                        </ul>
                        <a href="#contact" class="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500/5 hover:bg-teal-500 text-teal-500 hover:text-white w-full mt-5">Contratar</a>
                    </div><!--end content-->

                    <div class="group relative overflow-hidden shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 rounded-md h-fit">
                        <div class="bg-gradient-to-tr from-teal-500 to-teal-700 text-white py-2 px-6 h6 text-lg font-medium">Mais usado</div>
                        <div class="p-6">
                            <h6 class="font-semibold mb-5 text-xl">EasyGestor Plus</h6>

                            <div class="flex mb-5">
                                <span class="text-lg font-medium">R$</span>
                                <span class="price text-5xl h6 font-semibold mb-0">100,86</span>
                                <span class="text-lg font-medium self-end mb-1">/mês</span>
                            </div>

                            <ul class="list-none text-slate-400">
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>3 usuários</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Emissor de NF-e</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Controle de Estoque e Financeiro</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Ordem de Produção</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Emissão de Boleto Bancário</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>SPED Fiscal</span></li>
                            </ul>
                            <a href="#contact" class="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white w-full mt-5">Contratar</a>
                        </div>
                    </div><!--end content-->

                    <div class="group p-[1px] relative overflow-hidden shadow dark:shadow-gray-700 rounded-md bg-gradient-to-tr from-teal-500 to-teal-700 h-fit">
                        <div class="p-6 bg-white dark:bg-slate-900 rounded-md">
                            <h6 class="font-semibold mb-5 text-xl">EasyGestor Full</h6>

                            <div class="flex mb-5">
                                <span class="text-lg font-medium">R$</span>
                                <span class="price text-5xl h6 font-semibold mb-0">163,86</span>
                                <span class="text-lg font-medium self-end mb-1">/mês</span>
                            </div>

                            <ul class="list-none">
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>10 usuários</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Emissor de NF-e</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Controle de Estoque e Financeiro</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Ordem de Produção</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>Emissão de Boleto Bancário</span></li>
                                <li class="mb-1 flex"><i class="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2"></i> <span>SPED Fiscal</span></li>
                            </ul>
                            <a href="#contact" class="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500/5 hover:bg-teal-500 text-teal-500 hover:text-white w-full mt-5">Contratar</a>
                        </div>
                    </div><!--end content-->
                </div><!--end grid-->
            </div>
        </section>
</template>