<template>
     <section class="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800" id="contato">
            <div class="container relative">
                <div class="grid grid-cols-1 pb-6 text-center">
                    <h3 class="font-semibold text-2xl leading-normal mb-4">Entre em contato </h3>

                    <p class="text-slate-400 max-w-xl mx-auto">Contrate as Soluções Easy da SiD e tenha um suporte consultivo e ilimitado :</p>
                </div><!--end grid-->

                <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                    <div class="lg:col-span-7 md:col-span-6">
                        <img src="../assets/images/contact.svg" alt="">
                    </div>

                    <div class="lg:col-span-5 md:col-span-6">
                        <div class="lg:ms-5">
                            <div class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                <form>
                                    <div class="grid lg:grid-cols-12 grid-cols-1 gap-3">
                                        <div class="lg:col-span-12">
                                            <label for="nome" class="font-semibold">* Nome completo:</label>
                                            <input v-model="interessado.nome" name="nome" id="nome" type="text" class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Seu nome completo">
                                        </div>

                                        <div class="lg:col-span-12">
                                            <label for="empresa" class="font-semibold">Nome da Empresa:</label>
                                            <input v-model="interessado.empresa" name="empresa" id="empresa" type="text" class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Nome da Empresa">
                                        </div>
        
                                        <div class="lg:col-span-6">
                                            <label for="email" class="font-semibold">E-mail:</label>
                                            <input v-model="interessado.email" name="email" id="email" type="email" class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="seu@email.com">
                                        </div>

                                        <div class="lg:col-span-6">
                                            <label for="telefone" class="font-semibold">Telefone:</label>
                                            <input v-model="interessado.telefone" name="telefone" id="telefone" class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="(87) 9 9965-8729">
                                        </div>
    
                                        <div class="lg:col-span-4">
                                            <label for="cidade-uf" class="font-semibold">* UF:</label>
                                            <select @change="getCidades($event)" v-model="interessado.uf" id="uf" class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" name="uf">
                                                <option value="" selected="">Selecione o estado</option>
                                                <option v-for="uf in listEstados" :key="uf.id" :value="uf.id">{{ uf.nome }}</option>
                                            </select>
                                        </div>
                                        <div class="lg:col-span-8">
                                            <label for="cidade-uf" class="font-semibold">* Cidade:</label>
                                            <select v-model="interessado.cidade" name="cidade-uf" id="cidade-uf" class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0">
                                                <option v-for="cidade in listCidades" :key="cidade.id" :value="cidade.id">{{ cidade.nome }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <input type="hidden" v-model="interessado.campanha_id" name="campanha_id" id="campanha_id">
                                    <button @click.prevent="doPost" type="button" id="submit" name="send" class="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white mt-2">Enviar Mensagem</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end container-->
        </section>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            submitted: false,
            interessado: {
                nome: '',
                empresa: '',
                email: '',
                telefone: '',
                uf: 26,
                cidade: 2611101,
                campanha_id: 1
            },
            listEstados: [],
            listCidades: []
        }
    },
    mounted() {
        this.getEstados();
        this.getCidades();
    },
    methods: {
        onSubmit() {
            this.submitted = true;
            this.doPost();
        },
        doPost() {
            Swal.fire({
                title: 'Aguarde',
                text: 'Enviando...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            const promisse = axios.post('https://crm.sidsolucoes.com.br/api/interessado/gravar', this.interessado);

            return promisse.then((response) => {
                console.log(response);

                if (response.status == 200) {
                    Swal.close();
                    this.successModal();
                    return true;
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        getCidades() {
            Swal.fire({
                title: 'Atenção',
                text: 'Carregando...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            const promisse = axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.interessado.uf}/municipios`);
            return promisse.then((response) => {
                const list = response.data.map((item) => {
                    return {
                        id: item.id,
                        nome: item.nome
                    }
                })

                this.listCidades = list;
                Swal.close();
                return this.listCidades;
            }).catch((error) => {
                console.log(error);
            });
        },
        getEstados() {
            Swal.fire({
                title: 'Atenção',
                text: 'Carregando...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            const promisse = axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome');
            return promisse.then((response) => {
                const list = response.data.map((item) => {
                    return {
                        id: item.id,
                        nome: item.nome
                    }
                })

                this.listEstados = list;
                return this.listEstados;
            }).catch((error) => {
                console.log(error);
            });
        },
        successModal() {
            let timerInterval;
            Swal.fire({
            title: "Sucesso!",
            html: "Breve um dos nossos especialistas entrará em contato.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                // timer.textContent = `${Swal.getTimerLeft()}`;
                }, 2000);
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    this.clearForm();
                    console.log("I was closed by the timer");
                }
            });
        },
        clearForm() {            
            this.interessado = {
                nome: '',
                empresa: '',
                email: '',
                telefone: '',
                uf: 26,
                cidade: 2611101,
                campanha_id: 1
            }
        }
    }
}
</script>